import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import SEO from "../components/SEO";
import Header from "../components/header";
import Newsletter from "../components/newsletter";
import Footer from "../components/Footer";
import Banner from "../assets/img/vt-index-crop.png";
import MRP from "../assets/img/mrp_header.jpg";
import BannerEffect from "../assets/img/bannerEffect.png";
import Facebook from "../themes/custom/jobs_front/assets/img/facebook.png";
import Linkedin from "../themes/custom/jobs_front/assets/img/linkedin.png";
import Twitter from "../themes/custom/jobs_front/assets/img/twiter.png";
import Instagram from "../themes/custom/jobs_front/assets/img/instagram.png";
import AugustCover from "../assets/img/homepage/august-cover.png";
import OurStories from "../utils/stories";
import ThirtyBanner from "../assets/img/30/banner.png";
import MRPStory from "../assets/img/transformational/banner.png";
import Southern from "../assets/img/southern.png";

import testimonial_1 from "../assets/img/testimonials/testimonial_1.jpg";
import testimonial_2 from "../assets/img/testimonials/testimonial_2.jpg";
import testimonial_3 from "../assets/img/testimonials/testimonial_3.jpg";
import testimonial_4 from "../assets/img/testimonials/testimonial_4.jpg";
import testimonial_5 from "../assets/img/testimonials/testimonial_5.jpg";

const testimonials = [
  {
    image: testimonial_1,
    name: "Henda Saidi",
    company: "HR Director, Residence Hotel Gammarth Tunis",
    text: "“Our employees who engaged in the program have shown real enthusiasm and dedication. Issuing certifications contributed to creating a healthy competitive environment. We will scale our Tunis-based learnings to our new staff in the housekeeping, kitchen and restaurant/bar departments in our new hotel in Douz.”",
  },
  {
    image: testimonial_2,
    name: "Abir Jlassi",
    company: "Garde-manger Cuisinier, Residence Hotel Gammarth Tunis",
    text: "“The kitchen-related best practices and all the training materials have been relevant and useful to apply in my day-to-day work at the hotel. I would follow the same program for higher levels.”",
  },
  {
    image: testimonial_3,
    name: "Nejmeddine Daldou",
    company:
      "Owner of Subway Mahdia, a diving center in Mahdia and one of the twelve supported SMEs through our “Nature & Adventure SME” campaign",
    text: "“The Nature & Adventure campaign allowed us to take our diving center Subway Mahdia to the next level. This is such an incredibly important step forward for us. Only through your help are we now able to provide experiences to a much wider range of visitors.”",
  },
  {
    image: testimonial_4,
    name: "Boulbaba Soussi",
    company:
      "Owner of Snorkeling Kerkennah, a center promoting Kerkennah's treasures and one of the supported SMEs through our “Nature & Adventure SME” campaign",
    text: '“The "Nature & Adventure" campaign has had a significant impact on our business. We attracted more travelers from all over the world highlighting the beauty and diversity of Kerkennah as a unique tourism destination in Tunisia. We also succeeded in increasing awareness of the importance of ecotourism among the local community and visitors.”',
  },
  {
    image: testimonial_5,
    name: "Adel Katrouch",
    company: "President of the International Festival of Matmata.",
    text: "“We implemented some of the best practices we learned from the Festival Incubator program. Involving our community including the citizens, the local tourism businesses and artisans in the creation of participatory experiences was a huge success. We also improved many aspects of our festival management such as security, organization, and hygiene thank to the technical assistance, recommendations and mentoring advices we received from the program.”",
  },
];

const IndexPage = () => {
  const responsive = {
    0: { items: 1 },
    1024: { items: 3 },
  };
  const banner = {
    0: { items: 1 },
    1024: { items: 1 },
  };

  const { allStrapiArticle } = useStaticQuery(graphql`
    query {
      allStrapiArticle(sort: { fields: createdAt, order: DESC }) {
        nodes {
          createdAt
          slug
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.77, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        <script id="mcjs" type="text/javascript">
          {` !function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/10f941c353d7bcc0e30c9bae5/8e058e60d22dd09ce666113c8.js");`}
        </script>
      </Helmet>

      <SEO
        title="USAID Activity"
        description="Visit Tunisia Project"
        image={"../images/favicon.png"}
      />
      <div>
        <div
          className="dialog-off-canvas-main-canvas"
          data-off-canvas-main-canvas
        >
          <Header index />
          <main role="main">
            <div className="container-fluid">
              <div className="side-social-media d-flex flex-column align-items-center">
                <a
                  href="https://www.facebook.com/VisitTunisiaActivity"
                  className="nav-link"
                  target="_blank"
                >
                  <img src={Facebook} alt="" />
                </a>
                <a
                  href="https://www.linkedin.com/company/usaid-visit-tunisia-activity/"
                  className="nav-link"
                  target="_blank"
                >
                  <img src={Linkedin} alt="" style={{ width: "35px" }} />
                </a>
                <a
                  href="https://twitter.com/USAIDVT"
                  className="nav-link"
                  target="_blank"
                >
                  <img src={Twitter} alt="" />
                </a>
                <a
                  href="https://www.instagram.com/usaidvt/?hl=fr"
                  className="nav-link"
                  target="_blank"
                >
                  <img src={Instagram} alt="" style={{ width: "30px" }} />
                </a>
              </div>
              <div className="region region-content">
                <div data-drupal-messages-fallback className="hidden" />
                <div
                  id="block-jobs-front-content"
                  className="block block-system block-system-main-block"
                >
                  <article
                    data-history-node-id={166}
                    role="article"
                    typeof="schema:WebPage"
                    className="node node--type-page node--view-mode-full"
                  >
                    <span
                      property="schema:name"
                      content="Visit Tunisia Project|USAID"
                      className="rdf-meta hidden"
                    />
                    <div className="node__content">
                      <div className="field field--name-field-bricks field--type-bricks field--label-hidden field__items">
                        <div>
                          <AliceCarousel
                            autoPlay
                            autoPlayStrategy="none"
                            autoPlayInterval={6500}
                            animationDuration={1000}
                            animationType="fadeout"
                            infinite
                            touchTracking={false}
                            disableButtonsControls
                            items={allStrapiArticle.nodes
                              .slice(0, 3)
                              .map((article) => (
                                <div className="brick brick--type--home-banner brick--id--83 eck-entity">
                                  <div
                                    id="homepage-carousel"
                                    className="carousel slide"
                                    data-ride="carousel"
                                  >
                                    <a
                                      href={`/stories/${article.slug}`}
                                      target="_blank"
                                    >
                                      <div className="carousel-inner">
                                        <div className="carousel-item active">
                                          <GatsbyImage
                                            image={getImage(
                                              article.image?.localFile
                                            )}
                                            alt={article.title}
                                            style={{
                                              width: "100%",
                                              height: "180%",
                                              display: "block",
                                            }}
                                            className="d-block w-100"
                                          />
                                          <div className="overlay">
                                            <img
                                              src={BannerEffect}
                                              alt="usaid-visit-tunisia"
                                            />
                                          </div>
                                          <div
                                            className="carousel-caption"
                                            style={{ bottom: "10%" }}
                                          >
                                            <div className="typewriter">
                                              <h2 className="animate__animated animate__fadeInUp animate__slow">
                                                {article.title}
                                              </h2>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  fontSize: "26px",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <div
                                                  className="btn btn-danger aos-animate"
                                                  style={{ fontSize: "26px" }}
                                                >
                                                  Read more
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              ))
                              .concat([
                                <div className="brick brick--type--home-banner brick--id--83 eck-entity">
                                  <div
                                    id="homepage-carousel"
                                    className="carousel slide"
                                    data-ride="carousel"
                                  >
                                    <div className="carousel-inner">
                                      <div className="carousel-item active">
                                        <img src={Banner} alt="..." />
                                        <div className="overlay">
                                          <img src={BannerEffect} alt="" />
                                        </div>
                                        <div className="carousel-caption">
                                          <div className="typewriter">
                                            <h2 className="animate__animated animate__fadeInUp animate__slow">
                                              Building a{" "}
                                              <span>
                                                diverse, competitive and
                                                resilient
                                              </span>{" "}
                                              tourism sector in Tunisia
                                            </h2>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>,
                              ])}
                          />

                          <section className="jobs-support " id="storie-anchor">
                            <div className="brick brick--type--jobs-support brick--id--84 eck-entity container">
                              <div className="job-quote d-flex flex-column justify-content-center align-items-center text-center">
                                <h2
                                  className="quote aos-animate"
                                  data-aos="fade-up"
                                  data-aos-easing="ease-in-back"
                                >
                                  <div className="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item">
                                    USAID Visit Tunisia Activity - accelerating
                                    Tunisia’s tourism recovery and building a
                                    resilient industry.
                                  </div>
                                </h2>
                                <div
                                  className="description aos-animate"
                                  data-aos="fade-up"
                                  data-aos-easing="ease-in-back"
                                >
                                  <p>
                                    Visit Tunisia is a five-year, USAID-funded
                                    activity designed to grow and diversify
                                    Tunisian tourism, generating sustainable
                                    jobs and increasing tourism revenues. We
                                    also aim to develop a high-quality tourism
                                    industry and mitigate the impact of
                                    COVID-19.
                                  </p>
                                </div>
                                <div className="d-flex flex-md-row flex-column justify-content-between align-items-center">
                                  <a
                                    href="/about-us"
                                    target="_blank"
                                    className="mr-md-2 mb-md-0 mb-2 btn btn-white aos-animate"
                                    data-aos="fade-up"
                                    data-aos-easing="ease-in-back"
                                  >
                                    Read more
                                  </a>
                                  <a
                                    href="/opportunities"
                                    target="_blank"
                                    className="btn btn-danger aos-animate"
                                    data-aos="fade-up"
                                    data-aos-easing="ease-in-back"
                                  >
                                    PARTNER WITH US
                                  </a>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div
                  className="views-element-container block block-views block-views-blockour-stories-block-1"
                  id="block-views-block-our-stories-block-1"
                >
                  <div>
                    <section className="our-stories">
                      <div className="view  view-our-stories view-id-our_stories view-display-id-block_1 js-view-dom-id-e0d576f496d928ab87b641e89c08b27db23dc9301f3b1756b56e1208864b37c4">
                        <h2 className="title aos-animate" data-aos="fade-up">
                          Our Stories
                        </h2>
                        <div className="stories-holder">
                          <AliceCarousel
                            mouseTracking
                            animationDuration={9000}
                            responsive={responsive}
                            disableButtonsControls
                            items={OurStories.slice(
                              -6 - allStrapiArticle.nodes.length
                            ).map((story, index) => (
                              <div
                                key={index}
                                style={{
                                  marginBottom: "15px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  className=" owl-theme"
                                  style={{
                                    width: "463px",
                                    marginRight: "10px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <div className="views-row">
                                    <div className="views-field views-field-nothing">
                                      <span className="field-content">
                                        <div
                                          className="item story-card"
                                          data-aos="fade-up"
                                          data-aos-easing="ease-in-back"
                                          data-aos-anchor="#storie-anchor"
                                          data-aos-anchor-placement="top-top"
                                          data-aos-duration={600}
                                          data-aos-delay={0}
                                          style={{ width: "463px" }}
                                        >
                                          <a
                                            href={story.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src={story.image}
                                              height={337.5}
                                              width={450}
                                              typeof="Image"
                                              style={{ borderRadius: "15px" }}
                                              alt={story.title}
                                            />
                                            <div className="card-text">
                                              <h3
                                                style={{
                                                  fontSize: "22px",
                                                  lineHeight: "24px",
                                                  width: "90%",
                                                  margin: "32px 18px 8px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {story.title}
                                              </h3>
                                              <div>
                                                <span
                                                  style={{
                                                    color: "#c00630",
                                                    marginRight: "16px",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Articles{" "}
                                                </span>
                                              </div>
                                            </div>
                                          </a>
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          />
                        </div>

                        <a
                          href={"/stories"}
                          target="_blank"
                          className="btn btn-danger"
                        >
                          Our Stories
                        </a>
                      </div>
                    </section>
                  </div>
                </div>

                <div
                  className="views-element-container block block-views block-views-blockour-stories-block-2"
                  id="block-views-block-our-stories-block-2"
                >
                  <div>
                    <section
                      className="testimonials aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="ease-in-back"
                    >
                      <div className="view container view-our-stories view-id-our_stories view-display-id-block_2 js-view-dom-id-3f4ee673f4b874ee660e16d8cd20d66b791811e3304f719a4eff9db9612104ca">
                        <div className="view-header">
                          <h2>Words From Our Partners</h2>
                        </div>
                        <div className="custom-carousel owl-carousel">
                          <AliceCarousel
                            autoPlay
                            autoPlayStrategy="none"
                            autoPlayInterval={6500}
                            animationDuration={1000}
                            animationType="fadeout"
                            infinite
                            touchTracking={false}
                            disableButtonsControls
                            className="custom-carousel owl-carousel"
                            items={testimonials.map((testimonial, index) => (
                              <div className="item" key={index}>
                                <div className="d-flex flex-md-row flex-column-reverse justify-content-between">
                                  <div className="left-side d-flex flex-column justify-content-between">
                                    <p></p>
                                    <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                                      <p
                                        style={{
                                          fontSize: "26px",
                                          lineHeight: "33px",
                                          color: "#a3c4ee",
                                          marginTop: "0",
                                          marginBottom: "64px",
                                          width: "80%",
                                        }}
                                      >
                                        {testimonial.text}
                                      </p>
                                    </div>
                                    <div className="speaker">
                                      <small
                                        style={{
                                          fontSize: "22px",
                                          lineHeight: "33px",
                                          color: "white",
                                        }}
                                      >
                                        <div
                                          className="field field--name-field-name field--type-string field--label-hidden field__item"
                                          style={{
                                            fontSize: "22px",
                                            lineHeight: "33px",
                                            color: "white",
                                          }}
                                        >
                                          {testimonial.name}
                                        </div>
                                        —
                                      </small>
                                      <span>
                                        <div
                                          className="field field--name-field-company field--type-string field--label-hidden field__item"
                                          style={{
                                            fontSize: "14px",
                                            lineHeight: "33px",
                                            color: "white",
                                          }}
                                        >
                                          {testimonial.company}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="right-side">
                                    <div className="field field--name-field-success-image field--type-image field--label-hidden field__item">
                                      <div className="spinner"></div>
                                      <img
                                        src={testimonial.image}
                                        width="940px"
                                        height="940px"
                                        alt={testimonial.name}
                                        typeof="foaf:Image"
                                        className="image-style-rounded"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <Newsletter />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default IndexPage;
